<template>
  <div>
    <router-link to="/manage">
      <!--
      <v-img
        src="@assets/ennova_logotype.svg"
        contain
        height="48"
        class="my-3 mx-2"
      />
      -->
    </router-link>
  </div>
</template>

<script>
import utils from '@mixins/utils.js'

export default {
  name: 'NavDrawerLogo',
  mixins: [utils],
}
</script>
