var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:_vm.dark ? 'drawerShadow-dark' : 'drawerShadow',attrs:{"app":"","overflow":"","mini-variant":!_vm.drawer,"permanent":!_vm.$vuetify.breakpoint.mobile,"expand-on-hover":false && !_vm.drawer},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(!_vm.$vuetify.breakpoint.mobile)?_c('v-row',[_c('v-col',{staticClass:"py-8 px-6"},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer($event)}}})],1)],1):_c('NavDrawerLogo')]},proxy:true},{key:"append",fn:function(){return [_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{on:{"click":function($event){return _vm.setDarkMode(!_vm.dark)}}},[_vm._v(" "+_vm._s(_vm.dark ? 'mdi-brightness-4' : 'mdi-brightness-6')+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("v"+_vm._s(_vm.version))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-variant ")])]}}])},[_c('span',[_vm._v(" Versione: "),_c('b',[_vm._v(_vm._s(_vm.version))]),_c('br'),_vm._v(" Compilata: "),_c('b',[_vm._v(_vm._s(_vm.buildDate))]),_c('br'),_vm._v(" Hash: "),_c('strong',[_vm._v(_vm._s(_vm.buildHash)+"@"+_vm._s(_vm.buildBranch))])])])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('PermissionsRoutesCheck'),(_vm.currentCustomerId)?_c('v-list',{staticClass:"pb-0",attrs:{"dense":"","nav":""}},[_c('v-list-item',{attrs:{"to":{ name: 'customers_detail', params: { id: _vm.currentCustomerId } },"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-briefcase-account-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Profilo aziendale")])],1)],1)],1):_vm._e(),_c('v-spacer',{staticClass:"my-8"}),_vm._l((_vm.parsedMenu.slice(0, _vm.parsedMenu.length - 1)),function(section){return _c('v-list',{key:section.title,attrs:{"dense":"","nav":""}},_vm._l((_vm.activeSectionItems(section.items)),function(item){return _c('div',{key:item.title},[(item.items && _vm.activeSectionSubitems(item).length)?_c('v-list-group',{attrs:{"no-action":"","disabled":item.disabled,"group":item.section},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),!_vm.drawer && Object.assign({}, tooltip, menu)),[_vm._v(" "+_vm._s(item.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}}],null,true)},[_c('v-list',{attrs:{"width":"240"}},[_c('v-subheader',[_c('strong',[_vm._v(_vm._s(item.title))])]),_c('v-divider'),_vm._l((_vm.activeSectionSubitems(item)),function(subItem){return _c('v-list-item',{key:subItem.title,attrs:{"to":{ name: subItem.to },"color":"primary","exact":""}},[_c('v-list-item-content',{staticClass:"body-2"},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)})],2)],1)]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},_vm._l((_vm.activeSectionSubitems(item)),function(subItem){return _c('v-list-item',{key:subItem.title,attrs:{"to":{
            name: subItem.to,
            props: subItem.props,
            params: subItem.params,
            query: subItem.query,
          },"exact":""}},[_c('v-list-item-content',{staticClass:"body-2"},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)}),1):_vm._e()],1)}),0)}),(_vm.canUser('nav_entries', 'read') && _vm.links.length)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('div',[_c('v-list-group',{attrs:{"prepend-icon":"mdi-link","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Link rapidi")])],1)]},proxy:true}],null,false,4034697647)},_vm._l((_vm.links),function(link){return _c('NavEntry',_vm._b({key:link.id,staticStyle:{"padding-left":"5px"}},'NavEntry',link,false))}),1)],1)]):_vm._e(),_vm._l((_vm.parsedMenu.slice(_vm.parsedMenu.length - 1)),function(section){return _c('v-list',{key:section.title,attrs:{"dense":"","nav":""}},_vm._l((section.items),function(item){return _c('div',{key:item.title},[(item.items)?_c('v-list-group',{attrs:{"prepend-icon":item.icon,"no-action":"","group":item.section},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},_vm._l((item.items),function(subItem){return _c('v-list-item',{key:subItem.title,attrs:{"to":{
            name: subItem.to,
            props: subItem.props,
            params: subItem.params,
            query: subItem.query,
          },"exact":""}},[_c('v-list-item-content',{staticClass:"body-2"},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)}),1):_vm._e(),(!item.items)?_c('v-list-item',{staticClass:"mb-1",attrs:{"prepend-icon":item.icon,"to":{
          name: item.to,
          props: item.props,
          params: item.params,
          query: item.query,
        }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1):_vm._e()],1)}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }