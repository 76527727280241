export default [
  {
    title: 'Principale',
    items: [
      {
        title: 'Recupero Crediti',
        icon: 'mdi-cash-100',
        section: 'tickets',
        items: [
          {
            title: 'Ricerca',
            to: 'tickets_search',
          },
          {
            title: 'Non riconciliabili o eccedenze',
            to: 'payments_surplus',
          },
          {
            title: 'Estrazione Bollati',
            to: 'bollati_search',
          },
          {
            title: 'Download file',
            to: 'download',
            section: 'uploaded_files_progress',
            action: 'menu',
          },
        ],
      },
      {
        title: 'Tool Riconciliazione',
        icon: 'mdi-bank-check',
        section: 'bollato',
        items: [
          {
            title: 'Esegui riconciliazione',
            to: 'bollato_movimenti_create',
          },
        ],
      },
    ],
  },
  {
    title: 'Amministrazione',
    items: [
      {
        title: 'Gestione Utenze',
        icon: 'mdi-account-group',
        items: [
          {
            title: 'Ricerca',
            to: 'admin.users',
          },
          {
            title: 'Crea Nuovo',
            to: 'users_create',
          },
          {
            title: 'Gestione permessi',
            to: 'users_permissions',
            section: 'admin.permissions',
            action: 'menu',
          },
        ],
      },
      {
        title: 'Impostazioni',
        icon: 'mdi-cog',
        items: [
          {
            title: 'Attività Lavorazioni',
            to: 'ticket_activities_search',
            section: 'tickets_activities',
            action: 'search',
          },
          {
            title: 'Stati Lavorazioni',
            to: 'ticket_statuses_search',
            section: 'tickets_statuses',
            action: 'search',
          },
          {
            title: 'Esiti Lavorazioni',
            to: 'tickets_resolutions_search',
            section: 'tickets_resolutions',
            action: 'create',
          },
        ],
      },
    ],
  },

  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          title: 'DEV',
          items: [
            {
              title: 'Pagina di sviluppo',
              icon: 'mdi-logout-variant',
              to: '/manage/dev',
              loggedOnly: false,
            },
          ],
        },
      ]
    : []),
]
